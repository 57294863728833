// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-i-18-n-redirect-jsx": () => import("./../src/i18n/redirect.jsx" /* webpackChunkName: "component---src-i-18-n-redirect-jsx" */),
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-jsx": () => import("./../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-pakopeli-jsx": () => import("./../src/pages/pakopeli.jsx" /* webpackChunkName: "component---src-pages-pakopeli-jsx" */),
  "component---src-pages-saaressa-tapahtuu-jsx": () => import("./../src/pages/saaressa-tapahtuu.jsx" /* webpackChunkName: "component---src-pages-saaressa-tapahtuu-jsx" */),
  "component---src-pages-tekijat-ja-yhteystiedot-jsx": () => import("./../src/pages/tekijat-ja-yhteystiedot.jsx" /* webpackChunkName: "component---src-pages-tekijat-ja-yhteystiedot-jsx" */),
  "component---src-pages-tuotteet-ja-palvelut-jsx": () => import("./../src/pages/tuotteet-ja-palvelut.jsx" /* webpackChunkName: "component---src-pages-tuotteet-ja-palvelut-jsx" */)
}

