module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kotasaari","short_name":"kotasaari","start_url":"/","background_color":"#3b612b","theme_color":"#3b612b","display":"minimal-ui","icon":"src/images/kotasaari-logo-header.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
